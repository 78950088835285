import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
const LoginyPage = () => {
  return (
    <div>
            {/* Navbar */}
            <Header />
            {/* Main Content */}

            <div class="w3-row-padding mar-top-80">
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
              <h3 class="">LOGIN</h3>
              
              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
            <div class="w3-row-padding " style={{ margin: '14px 0px 100px 0px' }}>
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
              <p>Welcome back! Please log in to your account to access exclusive content, manage your projects, or interact with your personal dashboard.</p>
              <br/>
              <br/>
              <p>Username or Email Address</p>
              <p><input type="text" className="w3-input w3-border w3-width20per" placeholder="Username or Email Address" required name="Input" /></p>

<p>Password</p>
<p><input type="password" className="w3-input w3-border w3-width20per" placeholder="Password" required name="Input" /></p>
<div className="checkbox-container">
      <input type="checkbox" id="myCheckbox" />
      <label htmlFor="myCheckbox">Remember Me</label>
    </div>

<p><button>Login!</button></p>

<p>Forgot your password? <a href="/resetpassword" >Password Reset</a></p>

<p>Don't have an account yet? <a href="/signup" >Sign up here.</a></p>

<b>Security Notice:</b>
<p>We take your privacy seriously. Your login information is encrypted to ensure that your personal data remains secure. If you experience any issues logging in or need assistance, please contact us.</p>

<p>This setup covers the basics while keeping the focus on user accessibility and security. If you'd like any specific features, like multi-factor authentication or social logins, let me know!</p>
                
              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LoginyPage;
