import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from '../Pages/Dashboard';
import AboutPage from '../Pages/AboutPage';
import FAQPage from '../Pages/FAQPage';
import PrivacyPage from '../Pages/PrivacyPage';
import LoginPage from '../Pages/LoginPage';
import Base64Page from '../Pages/Base64Page';
import Blog from '../Pages/Blog';
import HexColorCode from '../Pages/HexColorCode';
import ImageToBase64 from '../Pages/ImageToBase64';
import Base64ToImage from '../Pages/Base64ToImage';
import MinifierPage from '../Pages/MinifierPage';
import JsonToXml from '../Pages/JsonToXml';
import HtmlFormatter from '../Pages/HtmlFormatter';
import XmlFormatter from '../Pages/XmlFormatter';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/base64" element={<Base64Page />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/hexcolorcode" element={<HexColorCode />} />
        <Route path="/Base64Conversion" element={<Base64ToImage />} />
        <Route path="/ImageConversion" element={<ImageToBase64 />} />
        <Route path="/json-minifier" element={<MinifierPage type="json" />} />
        <Route path="/xml-minifier" element={<MinifierPage type="xml" />} />
        <Route path="/jsontoxml" element={<JsonToXml type="jsontoxml"/>} />
        <Route path="/xmltojson" element={<JsonToXml type="xmltojson"/>} />
        <Route path="/htmlformatter" element={<HtmlFormatter />} />
        <Route path="/xmlformatter" element={<XmlFormatter />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;