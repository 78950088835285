export const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };
  
  export const handleDrop = (e, setFile) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setFile(file);
    }
  };
  
  export const handleConvert = (file, setBase64, setIsConverting) => {
    if (file) {
      setIsConverting(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64(reader.result);
        setIsConverting(false);
      };
      reader.readAsDataURL(file);
    }
  };

  export const handleBase64Change = (e, setBase64, setImageSrc) => {
    const base64 = e.target.value;
    setBase64(base64);
  
    // Validate if the base64 string starts with image data prefix
    if (base64.startsWith('data:image')) {
      setImageSrc(base64);
    } else {
      setImageSrc('');
    }
  };
  
  export const handleDownload = (imageSrc, toast) => {
    if (imageSrc) {
      const link = document.createElement('a');
      link.href = imageSrc;
      link.download = 'image.png';
      link.click();
  
      toast.success('Image downloaded successfully!');
    }
  };
  