import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const AboutPage = () => {
  return (
    <div>
            {/* Navbar */}
            <Header />
            {/* Main Content */}

            <div class="w3-row-padding mar-top-80">
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
              <h3 class="">ABOUT THE COMPANY</h3>
              <p class="w3-large">Key features of our company</p>
              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
            <div class="w3-row-padding" style={{ margin: '14px 0px 100px 0px' }}>
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
                
                <p>Welcome! I'm [Your Name], a [your profession or passion] with a love for [key interest or specialty]. My journey started [brief background or origin], and over the years, I've had the pleasure of exploring and growing in the world of [specific field/industry].</p>

                <p>Driven by curiosity and a passion for [mention specific skill or talent], I've worked on [types of projects, accomplishments, or collaborations]. Whether it's [list a couple of your skills, like "writing stories that connect with people" or "building innovative web applications"], I'm always excited to learn and push the boundaries of what's possible.</p>

                <p>Beyond my professional life, I'm deeply passionate about [personal hobbies, interests, or causes]. I believe that [mention any personal philosophy, like "creativity thrives in collaboration" or "curiosity fuels growth"], and I aim to live that out every day—whether through my work or in my everyday adventures.</p>

                <p>Feel free to explore my site, check out my [portfolio, blog, etc.], and get in touch if you'd like to collaborate or just chat! I'm always excited to connect with like-minded individuals and hear about your own stories and experiences.</p>

                <p>Thanks for stopping by!</p>
              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AboutPage;