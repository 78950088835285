
export const convertJsonXmlData = (type, inputData) => {
    let convertedData = '';
    let isError = false;
  
    try {
      if (type === 'jsontoxml') {
        // Convert JSON to XML
        const json = JSON.parse(inputData);
        convertedData = jsonToXml(json);
      } else if (type === 'xmltojson') {
        // Convert XML to JSON
        convertedData = JSON.stringify(xmlToJson(inputData), null, 4);
      }
    } catch (error) {
      isError = true;
      convertedData = 'Invalid data format.';
    }
  
    return { convertedData, isError };
  };
  
  // Helper function to convert JSON to XML
  const jsonToXml = (json, indent = '') => {
    let xml = '';
    for (let key in json) {
      if (json.hasOwnProperty(key)) {
        if (typeof json[key] === 'object') {
          xml += `${indent}<${key}>\n${jsonToXml(json[key], indent + '  ')}${indent}</${key}>\n`;
        } else {
          xml += `${indent}<${key}>${json[key]}</${key}>\n`;
        }
      }
    }
    return xml;
  };
  
  // Helper function to convert XML to JSON
  const xmlToJson = (xml) => {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(xml, 'text/xml');
    return xmlToJsonHelper(xmlDoc.documentElement);
  };
  
  // Recursive helper function to handle XML to JSON conversion
  const xmlToJsonHelper = (node) => {
    let obj = {};
    
    if (node.nodeType === 1) { // element node
      if (node.attributes.length > 0) {
        for (let j = 0; j < node.attributes.length; j++) {
          let attribute = node.attributes.item(j);
          obj[`@${attribute.nodeName}`] = attribute.nodeValue;
        }
      }
    }
    
    if (node.hasChildNodes()) {
      for (let i = 0; i < node.childNodes.length; i++) {
        let child = node.childNodes.item(i);
        if (child.nodeType === 3) { // text node
          if (child.nodeValue.trim()) {
            obj['#text'] = child.nodeValue;
          }
        } else {
          let childName = child.nodeName;
          if (typeof obj[childName] === 'undefined') {
            obj[childName] = xmlToJsonHelper(child);
          } else {
            if (typeof obj[childName].push === 'undefined') {
              let old = obj[childName];
              obj[childName] = [old];
            }
            obj[childName].push(xmlToJsonHelper(child));
          }
        }
      }
    }
    
    return obj;
  };