import React from 'react';
import '../css/site.css';
import '../css/w3.css'; 
import mainLogo from '../images/logo-2.png';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faInfoCircle, 
  faQuestionCircle, 
  faUserSecret, 
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';

const Header = () => {

  const navigate = useNavigate();
  const handleHomeClick = () => navigate('/');
  const handleAboutClick = () => navigate('/about');
  const handleFAQClick = () => navigate('/faq');
  const handlePrivacyClick = () => navigate('/privacy');
  const handleLoginClick = () => navigate('/login');

  return (
    <div>
      {/* Navbar */}
      <div className="w3-top">
        <div className="w3-bar w3-black w3-card padding0" id="myNavbar">
          <a onClick={handleHomeClick}  className="w3-bar-item w3-wide headerpadding pointer">
            <img id="img01" className="w3-image padding0 logo" src={mainLogo}  alt="Logo" />
          </a>
          <div className="w3-right w3-hide-small">
            <a onClick={handleAboutClick} className="w3-bar-item w3-button">
              <FontAwesomeIcon icon={faInfoCircle} /> ABOUT
            </a>
            <a onClick={handleFAQClick} className="w3-bar-item w3-button">
              <FontAwesomeIcon icon={faQuestionCircle} /> FAQ
            </a>
            <a onClick={handlePrivacyClick} className="w3-bar-item w3-button">
              <FontAwesomeIcon icon={faUserSecret} /> Privacy
            </a>
            {/* <a onClick={handleLoginClick} className="w3-bar-item w3-button">
              <FontAwesomeIcon icon={faUserCircle} /> Login
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;