// src/htmlFormatterUtils.js
export const formatHtml = (html) => {
    // Simple logic to format HTML by adding new lines between tags
    try {
      const formatted = html
        .split(/>\s*</) // Add new lines between HTML tags
        .join(">\n<")
        .trim();
  
      return formatted;
    } catch (error) {
      return "Invalid HTML";
    }
  };
  