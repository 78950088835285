import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const AboutPage = () => {
  return (
    <div>
            {/* Navbar */}
            <Header />
            {/* Main Content */}

            <div class="w3-row-padding mar-top-80">
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
              <h3 class="">Hex Color Codes</h3>
              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
            <div class="w3-row-padding" style={{ margin: '14px 0px 100px 0px' }}>
              <div class="w3-col m2"> &nbsp;</div>
              <div class="w3-col m8">
                
              </div>
              <div class="w3-col m2">&nbsp;</div>
            </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AboutPage;