// src/XmlFormatter.js
import React, { useState } from "react";
import { formatXml } from "../Conversion/XmlFormat";
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const XmlFormatter = () => {
    const [inputXml, setInputXml] = useState("");
    const [formattedXml, setFormattedXml] = useState("");

    const handleFormat = () => {
        const formatted = formatXml(inputXml);
        setFormattedXml(formatted);
    };

    return (
        <div>
            <Header />
            <div className="w3-container w3-light-grey" style={{ padding: '95px 66px' }} id="contact1">
                <div className="w3-row">
                    <div className="w3-col m5">
                    <h1>XML Formatter</h1>
                        <textarea
                            className="w3-input w3-border textarea"
                            rows="28"
                            placeholder="Enter your XML here..."
                            value={inputXml}
                            onChange={(e) => setInputXml(e.target.value)}
                        />
                    </div>
                    <div className="w3-col m2"  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    
                        <button onClick={handleFormat} disabled={!inputXml.trim()} className="mar-top-80">
                            Format XML
                        </button>
                    </div>
                    <div className="w3-col m5">
                        <h2>Formatted XML</h2>
                        <textarea
                            className="w3-input w3-border textarea"
                            rows="28"
                            value={formattedXml}
                            readOnly
                            placeholder="Formatted XML will appear here..."
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
};

export default XmlFormatter;
