// src/pages/Dashboard.js

import React, { useState, useRef } from 'react';
import '../css/site.css';
import '../css/w3.css'; 
import '../css/dashboard.css'; 
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlus, faMinus, faExpand, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { copyToClipboard, handleDownload, zoomIn, zoomOut, openFullscreen } from '../Helpers/CommonHelpers';
import { Conversions } from '../Conversion/Conversions';

const Dashboard = () => {
  const [inputJson, setInputJson] = useState('');
  const [outputData, setOutputData] = useState('');
  const [isError, setIsError] = useState(false);
  const [fontSize, setFontSize] = useState(16); // Default font size
  const [tabSpace, setTabSpace] = useState(2); // Default tab space

  const inputRef = useRef(null);
  const outputRef = useRef(null);

  const handleCopy = () => {
    copyToClipboard(outputData);
  };

  const handleConversion = () => {
    Conversions(inputJson, setOutputData, setIsError, tabSpace);
  };

  return (
    <div>
      <Header />

      <div className="w3-container w3-light-grey" style={{ padding: '95px 66px' }} id="contact1">
        <div className="w3-row">
          <div className="w3-col m5">
            <div className="textarea-container">
              <textarea
                className="w3-input w3-border textarea"
                rows="32"
                placeholder="Input JSON"
                required
                name="Input"
                value={inputJson}
                onChange={(e) => {
                  setInputJson(e.target.value);
                  setIsError(false);
                }}
                ref={inputRef}
              />
            </div>
          </div>
          <div className="w3-col m2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="w3-margin-bottom">
              <select
                id="tabSpace"
                value={tabSpace}
                onChange={(e) => setTabSpace(Number(e.target.value))}
                className="w3-select"
              >
                <option value={2}>2 Spaces</option>
                <option value={3}>3 Spaces</option>
                <option value={4}>4 Spaces</option>
              </select>
            </div>
            <button
              className="w3-button w3-blue w3-margin-top"
              style={{ display: 'block', width: '50%' }}
              onClick={handleConversion}
            >
              Convert
            </button>

            <button
              className="w3-button w3-blue w3-margin-top"
              style={{ display: 'block', width: '50%' }}
              onClick={() => handleDownload(outputData)}
            >
              Download
            </button>
          </div>
          <div className="w3-col m5">
            <div style={{ position: 'relative' }}>
              <div className="textarea-container">
                <textarea
                  className="w3-input w3-border output-textarea textarea"
                  rows="30"
                  placeholder="Output"
                  required
                  name="Output"
                  value={outputData}
                  readOnly
                  style={{ color: isError ? 'red' : 'black', fontSize: `${fontSize}px` }}
                  ref={outputRef}
                />
              </div>
              <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', flexDirection: 'column' }}>
                <button
                  className="w3-button w3-blue"
                  style={{ marginBottom: '5px' }}
                  onClick={handleCopy}
                  title="Copy To Clipboard"
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
                <button
                  className="w3-button w3-blue"
                  style={{ marginBottom: '5px' }}
                  onClick={() => zoomIn(setFontSize)}
                  title="Zoom In"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <button
                  className="w3-button w3-blue"
                  style={{ marginBottom: '5px' }}
                  onClick={() => zoomOut(setFontSize)}
                  title="Zoom Out"
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <button
                  className="w3-button w3-blue"
                  style={{ marginBottom: '5px' }}
                  onClick={openFullscreen}
                  title="View Full Screen"
                >
                  <FontAwesomeIcon icon={faExpand} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Dashboard;
