import React, { useState } from 'react';
import { handleBase64Change, handleDownload } from '../Conversion/Base64';
import '../css/Base64.css';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Base64ToImage = () => {
  const [base64, setBase64] = useState('');
  const [imageSrc, setImageSrc] = useState('');

  const handleBase64ChangeWrapper = (e) => handleBase64Change(e, setBase64, setImageSrc);
  const handleDownloadWrapper = () => handleDownload(imageSrc, toast);

  return (
    <div className="base64-to-image">
      <Header />
      <div className="w3-container w3-light-grey" style={{ padding: '95px 66px' }} id="contact1">
                <div className="w3-row">
                    <div className="w3-col m5">
                    <h1>Base64 to Image Conversion</h1>
                    <textarea
                          value={base64}
                          onChange={handleBase64ChangeWrapper}
                          rows="18"
                          placeholder="Enter Base64 string here"
                          className="base64-textarea"
                    />
                    </div>
                    <div className="w3-col m2"  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    
                        <button className="mar-top-80">
                            Convert to Image
                        </button>
                    </div>
                    <div className="w3-col m5">
                        <h2>Image Preview</h2>
                        {imageSrc && (
            <div className="image-preview-section">
              <img src={imageSrc} alt="Preview" className="preview-image" />
              <button
                className="download-button"
                onClick={handleDownloadWrapper}
              >
                Download
              </button>
            </div>
          )}
                    </div>
                </div>
            </div>
      
      <Footer />
    </div>
  );
};

export default Base64ToImage;
