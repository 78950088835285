import React, { useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../css/Base64.css';
import { handleFileChange, handleDrop, handleConvert } from '../Conversion/Base64';
import { copyToClipboard } from '../Helpers/CommonHelpers'; 

const ImageToBase64 = () => {
  const [base64Image, setBase64Image] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isConverting, setIsConverting] = useState(false);

  const handleFileChangeWrapper = (e) => handleFileChange(e, setSelectedFile);
  const handleDropWrapper = (e) => handleDrop(e, setSelectedFile);
  const handleConvertWrapper = () => handleConvert(selectedFile, setBase64Image, setIsConverting);
  const handleCopyWrapper = () => copyToClipboard(base64Image);

  return (
    <div>
      <Header />

      <div className="container">
        <div className="header">
          <h3>Base 64 Conversion</h3>
        </div>

        <div className="uploadSection">
          <div
            className="dropZone"
            onDrop={handleDropWrapper}
            onDragOver={(e) => e.preventDefault()}
          >
            <input
              type="file"
              accept="image/*"
              id="fileUpload"
              onChange={handleFileChangeWrapper}
              style={{ display: 'none' }}
            />
            <label htmlFor="fileUpload" className={`uploadButton ${selectedFile ? 'file-selected' : ''}`}>
              <span>{selectedFile ? selectedFile.name : 'Choose Image or Drag Here'}</span>
            </label>
          </div>
          <button
            onClick={handleConvertWrapper}
            className="convertButton"
            disabled={!selectedFile || isConverting}
          >
            Convert to Base64
          </button>
        </div>

        <div className="outputSection">
          <textarea
            rows="20"
            className="textArea"
            value={base64Image}
            readOnly
            placeholder="Base64 URL will appear here..."
          />
          <button onClick={handleCopyWrapper} className="copyButton">
            Copy
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ImageToBase64;
