import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlus, faMinus, faExpand, faDownload } from '@fortawesome/free-solid-svg-icons';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { copyToClipboard, handleDownload, zoomIn, zoomOut, openFullscreen } from '../Helpers/CommonHelpers';
import { convertJsonXmlData } from '../Conversion/JsonXmlConvertor'

const JsonToXml = ({ type }) => { // Receiving 'type' prop from route

    const [inputData, setInputData] = useState('');
    const [outputData, setOutputData] = useState('');
    const [isError, setIsError] = useState(false);
    const [fontSize, setFontSize] = useState(14);
    const inputRef = useRef(null);
    const outputRef = useRef(null);

    const handleConversion = () => {
        const { convertedData, isError } = convertJsonXmlData(type, inputData);
        setOutputData(convertedData);
        setIsError(isError);
    };

    const handleCopy = () => {
        copyToClipboard(outputData);
    };

    return (
        <div>
            <Header />
            <div className="w3-container w3-light-grey" style={{ padding: '95px 66px' }}>
                <div className="w3-row">
                    <div className="w3-col m5">
                        <div className="textarea-container">
                            <textarea
                                className="w3-input w3-border textarea"
                                rows="30"
                                placeholder={`Input ${type === 'jsontoxml' ? 'JSON' : 'XML'}`}
                                value={inputData}
                                onChange={(e) => setInputData(e.target.value)}
                                ref={inputRef}
                                style={{ width: '100%', marginBottom: '20px' }}
                            />
                        </div>
                        
                    </div>
                    <div className="w3-col m2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <button
                                className="w3-button w3-blue w3-margin-top"
                                style={{ width: '50%', margin: '20px' }}
                                onClick={handleConversion}
                            >
                                Convert
                            </button>
                        </div>
                        <div className="w3-col m5">
                            <div style={{ position: 'relative' }}>
                                <div className="textarea-container">
                                    <textarea
                                        className="w3-input w3-border output-textarea textarea"
                                        rows="30"
                                        placeholder="Output"
                                        value={outputData}
                                        readOnly
                                        style={{ color: isError ? 'red' : 'black', fontSize: `${fontSize}px`, width: '100%' }}
                                        ref={outputRef}
                                    />
                                </div>
                                <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', flexDirection: 'column' }}>
                                    <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={handleCopy} title="Copy To Clipboard">
                                        <FontAwesomeIcon icon={faCopy} />
                                    </button>
                                    <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => zoomIn(setFontSize)} title="Zoom In">
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                    <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => zoomOut(setFontSize)} title="Zoom Out">
                                        <FontAwesomeIcon icon={faMinus} />
                                    </button>
                                    <button className="w3-button w3-blue" style={{ marginBottom: '5px' }} onClick={() => openFullscreen(outputRef)} title="View Full Screen">
                                        <FontAwesomeIcon icon={faExpand} />
                                    </button>
                                    <button className="w3-button w3-blue" style={{ marginTop: '5px' }} onClick={() => handleDownload(outputData)} title="Download File">
                                        <FontAwesomeIcon icon={faDownload} />
                                    </button>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default JsonToXml;
