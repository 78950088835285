// src/HtmlFormatter.js
import React, { useState } from "react";
import { formatHtml } from "../Conversion/HtmlFormat";
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const HtmlFormatter = () => {
    const [inputHtml, setInputHtml] = useState("");
    const [formattedHtml, setFormattedHtml] = useState("");

    const handleFormat = () => {
        const formatted = formatHtml(inputHtml);
        setFormattedHtml(formatted);
    };

    return (
        <div>
            <Header/>
            <div className="w3-container w3-light-grey" style={{ padding: '95px 66px' }} id="contact1">
                <div className="w3-row">
                    <div className="w3-col m5">
                    <h1>HTML Formatter</h1>
                        <textarea
                           className="w3-input w3-border textarea"
                            rows="28"
                            placeholder="Enter your HTML here..."
                            value={inputHtml}
                            onChange={(e) => setInputHtml(e.target.value)}
                        />
                    </div>
                    <div className="w3-col m2"  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <button onClick={handleFormat} disabled={!inputHtml.trim()}  className="mar-top-80">
                            Format HTML
                        </button>
                    </div>
                    <div className="w3-col m5">
                        <h2>Formatted HTML</h2>
                        <textarea
                            className="w3-input w3-border textarea"
                            rows="28"
                            value={formattedHtml}
                            readOnly
                            placeholder="Formatted HTML will appear here..."
                        />
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default HtmlFormatter;
