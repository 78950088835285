import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const AboutPage = () => {
  return (
    <div>
            {/* Navbar */}
            <Header />
            {/* Main Content */}

            <div className="w3-container w3-content"  style={{ marginTop: '104px' }}> 
              <div className="w3-row">
                <div className="w3-col m3">
                  <div className="w3-card w3-white w3-round marginbottom20 menu"   style={{ marginTop: '16px'}}>
                      <div className='menu-head'>Categories</div>
                      <div className='menu-item'><a className="w3-bar-item w3-button w3-hover-black" href="#">Link</a></div>
                      <div className='menu-item'><a className="w3-bar-item w3-button w3-hover-black" href="#">Link</a></div>
                      <div className='menu-item'><a className="w3-bar-item w3-button w3-hover-black" href="#">Link</a></div>
                      <div className='menu-item'><a className="w3-bar-item w3-button w3-hover-black" href="#">Link</a></div>
                  </div>
                  <div className="w3-card w3-white w3-round marginbottom20">
                      <p className="w3-border w3-padding-large w3-padding-32 w3-center">AD</p>
                  </div>
                  <div className="w3-card w3-white w3-round marginbottom20">
                    <div className='menu-head'>Tag Cloud</div>
                    <p className='cloud-item'>
                        <span className="w3-tag w3-theme-d5">News</span>
                        <span className="w3-tag w3-theme-d4">W3Schools</span>
                        <span className="w3-tag w3-theme-d3">Labels</span>
                        <span className="w3-tag w3-theme-d2">Games</span>
                        <span className="w3-tag w3-theme-d1">Friends</span>
                        <span className="w3-tag w3-theme">Games</span>
                        <span className="w3-tag w3-theme-l1">Friends</span>
                        <span className="w3-tag w3-theme-l2">Food</span>
                        <span className="w3-tag w3-theme-l3">Design</span>
                        <span className="w3-tag w3-theme-l4">Art</span>
                        <span className="w3-tag w3-theme-l5">Photos</span>
                      </p>
                  </div>
                  <div className="w3-card w3-white w3-round marginbottom20">
                      <p className="w3-border w3-padding-large w3-padding-64 w3-center">AD</p>
                  </div>
                </div>
                <div className="w3-col m9">
                    <div className="w3-card w3-white w3-round w3-margin padding20">
                      <h1 className="w3-text-teal">Heading</h1>
                      <p className="post-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                        dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                    <div className="w3-card w3-white w3-round w3-margin padding20">
                      <h1 className="w3-text-teal">Heading</h1>
                      <p className='post-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                        dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                    <div className="w3-card w3-white w3-round w3-margin padding20">
                      <h1 className="w3-text-teal">Heading</h1>
                      <p className='post-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                        dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
              </div>
            </div>  
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AboutPage;