// src/xmlFormatterUtils.js
export const formatXml = (xml) => {
    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xml, "application/xml");
  
      // Check for parsing errors
      const parseError = xmlDoc.getElementsByTagName("parsererror");
      if (parseError.length > 0) {
        return "Invalid XML";
      }
  
      // Serialize the formatted XML with indentation
      const serializer = new XMLSerializer();
      const xmlString = serializer.serializeToString(xmlDoc);
  
      const formattedXml = xmlString
        .replace(/(>)(<)(\/*)/g, "$1\n$2$3") // Add newlines between tags
        .replace(/(<[^/!].+?>)(.+)(<\/.+?>)/g, "$1\n  $2\n$3"); // Indent content between tags
  
      return formattedXml;
    } catch (error) {
      return "Invalid XML";
    }
  };
  