// src/Conversion/Conversions.js

export const Conversions = (inputJson, setOutputData, setIsError, tabSpace) => {
    try {
      // Reset error state
      setIsError(false);
  
      // Parse and format the JSON with the specified tab space
      const parsedJson = JSON.parse(inputJson);
      const result = JSON.stringify(parsedJson, null, tabSpace);
  
      setOutputData(result);
    } catch (error) {
      // Set error message and mark as error
      setOutputData(`Error: ${error.message}`);
      setIsError(true);
    }
  };
  