// minifierLogic.js

export const minifyData = (type, inputData) => {
    try {
      let minified;
      if (type === 'json') {
        minified = JSON.stringify(JSON.parse(inputData), null, 0);
      } else if (type === 'xml') {
        // XML minification logic
        minified = inputData.replace(/\s+/g, ' ').trim();
      }
      return { minified, isError: false };
    } catch (error) {
      return { minified: 'Error: Invalid input data.', isError: true };
    }
  };
  
  export const zoomIn = (fontSize, setFontSize) => setFontSize(fontSize + 2);
  export const zoomOut = (fontSize, setFontSize) => setFontSize(fontSize - 2);
  export const openFullscreen = (outputRef) => outputRef.current.requestFullscreen();
  export const handleDownload = (outputData, type) => {
    const blob = new Blob([outputData], { type: 'text/plain;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `minified.${type === 'json' ? 'json' : 'xml'}`;
    link.click();
  };
  